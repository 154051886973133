.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.85);
    color: white;
    font-size: 2em;
    text-align: center;
    padding-top: 40vh; /* roughly centers the message vertically */
    z-index: 1000;
  }
  