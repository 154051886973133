html {
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  overflow: hidden;
  background-color: #000;
}
